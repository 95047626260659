<script setup>
import {
  DrawingUtils,
  PoseLandmarker,
  FaceLandmarker,
  HandLandmarker,
} from "@mediapipe/tasks-vision";

import { ref, onMounted, onUnmounted, computed } from "vue";
import {
  createLandmarkers,
  getPoseInfo,
  recognizePose,
  recognizeFace,
  getHandAngles,
  getHandPalmDirection,
  getFaceScoreFromBshape,
} from "@/api/motion";
import BetyCameraIcon from "@/components/bety/icon/BetyCameraIcon";
import BetyCameraBlock from "@/components/bety/BetyCameraBlock";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();

const props = defineProps({
  type: {
    type: String,
    required: true,
    default: "",
  },
  pose: {
    type: Boolean,
    required: true,
    default: false,
  },
  hand: {
    type: Boolean,
    required: true,
    default: false,
  },
  face: {
    type: Boolean,
    required: true,
    default: false,
  },
  block: {
    type: Boolean,
    required: true,
    default: true,
  },
  blockType: {
    type: String,
    required: true,
    default: "normal",
  },
  shutter: {
    type: Boolean,
    required: true,
    default: true,
  },
  numPerson: {
    type: Number,
    required: true,
    default: 1,
  },
});

const viewingElement = ref(null);
const videoElement = ref(null);
const canvasElement = ref(null);
const videoCoverElement = ref(null);
const isCameraLoading = computed(() => !store.state.Camera.isUsable); //ref(true);

let drawingUtils = undefined;
let ctx = undefined;
let poseLandmarker = undefined;
let faceLandmarker = undefined;
let handLandmarker = undefined;
let pose_name = ref("");
let face_name = ref("");
let hand_name = ref("");
let camera_cover_flag = false;

const shutterSound = new Audio(require("@/assets/camera_shutter.mp3"));
const popSound = new Audio(require("@/assets/pop.mp3"));

function remoteAction(action) {
  if (!props.shutter) return;
  if (action === "up") {
    shutterSound.currentTime = 0;
    shutterSound.play();
    if (props.type === "pose") {
      if (pose_name.value == "circle") {
        store.commit("Space/play");
      } else if (pose_name.value != "")
        store.commit("Block/pushBlock", pose_name.value);
    } else if (props.type === "face") {
      if (face_name.value == "circle") {
        store.commit("Space/play");
      } else if (face_name.value != "")
        store.commit("Block/pushBlock", face_name.value);
    } else if (props.type === "hand") {
      if (hand_name.value == "circle") {
        store.commit("Space/play");
      } else if (hand_name.value != "" && hand_name.value != "grab")
        store.commit("Block/pushBlock", hand_name.value);
    }
  }
  if (action === "down") {
    popSound.currentTime = 0;
    popSound.play();
    store.commit("Block/popBlock");
  }
}

document.addEventListener("keydown", (event) => {
  if (!store.state.Camera.isUsable) return;
  if (!store.state.Camera.shutterEnable) return;
  if (event.key == "PageUp") {
    remoteAction("up");
  }
  if (event.key == "PageDown") {
    remoteAction("down");
  }
});

// const clickView = () => {
//   if (!store.state.Camera.isUsable) return;
//   if (!store.state.Camera.shutterEnable) return;
//   remoteAction("up");
// };

document.addEventListener("click", () => {
  if (!store.state.Camera.isUsable) return;
  if (!store.state.Camera.shutterEnable) return;
  remoteAction("up");
});

document.addEventListener("contextmenu", (event) => {
  if (!store.state.Camera.isUsable) return;
  if (!store.state.Camera.shutterEnable) return;
  event.preventDefault();
  remoteAction("down");
});

onUnmounted(() => {
  console.log("unmounted");
});

function detectDeviceType() {
  // 현재 뷰포트의 너비를 가져오기
  var viewportWidth = window.innerWidth;

  // 기기 타입을 판별
  if (viewportWidth < 768) {
    return "Mobile"; // 모바일
  } else if (viewportWidth >= 768 && viewportWidth < 1200) {
    return "Tablet"; // 태블릿
  } else {
    return "Desktop"; // 데스크탑
  }
}

onMounted(async () => {
  let numPoses = 1;
  let numFaces = 1;
  let numHands = 2;
  let routeMinor = router.currentRoute.value.params.minor || "";
  if (routeMinor === "represent") numPoses = 2;
  if (routeMinor === "wordselect") numHands = 1;
  if (routeMinor === "order") numHands = 1;
  if (routeMinor === "math_represent") numHands = 1;

  console.log("ImPORTASER!!: " + numPoses);

  let landmarkers = await createLandmarkers(numPoses, numFaces, numHands);
  poseLandmarker = landmarkers[0];
  faceLandmarker = landmarkers[1];
  handLandmarker = landmarkers[2];
  ctx = canvasElement.value.getContext("2d");
  drawingUtils = new DrawingUtils(ctx);

  const width = parseInt(window.getComputedStyle(viewingElement.value).width);
  const height = parseInt(window.getComputedStyle(viewingElement.value).height);
  // let facingMode = "environment";
  let facingMode = "user";
  if (detectDeviceType() !== "Desktop") {
    facingMode = "user";
  }
  canvasElement.value.width = width;
  canvasElement.value.height = height;
  videoElement.value.style.width = width + "px";
  videoElement.value.style.height = height + "px";
  canvasElement.value.style.width = width + "px";
  canvasElement.value.style.height = height + "px";

  const hasGetUserMedia = () => !!navigator.mediaDevices?.getUserMedia;
  if (hasGetUserMedia()) {
    const constraints = {
      video: {
        width,
        height,
        facingMode,
      },
    };
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      videoElement.value.srcObject = stream;
      videoElement.value.addEventListener("loadeddata", predictWebcam);
      store.commit("Camera/setIsUsable", true);
    });
  } else {
    console.warn("getUserMedia() is not supported by your browser");
  }

  store.commit("Camera/setCameraSize", {
    width,
    height,
  });
});

async function predictWebcam() {
  let startTimeMs = performance.now();

  ctx.save();
  ctx.clearRect(0, 0, canvasElement.value.width, canvasElement.value.height);

  // Detection & Recognization
  let poseResults = undefined;
  if (props.pose) {
    poseResults = poseLandmarker.detectForVideo(
      videoElement.value,
      startTimeMs
    );
  }
  let faceResults = undefined;
  if (props.face) {
    faceResults = faceLandmarker.detectForVideo(
      videoElement.value,
      startTimeMs
    );
  }
  let handResults = undefined;
  if (props.hand) {
    handResults = handLandmarker.detectForVideo(
      videoElement.value,
      startTimeMs
    );
  }

  // Cover
  if (props.type === "pose") {
    if (poseResults.landmarks.length == 0 && camera_cover_flag) {
      videoCoverElement.value.style.opacity = 0;
      videoElement.value.style.opacity = 1;
      camera_cover_flag = false;
    } else if (poseResults.landmarks.length != 0 && !camera_cover_flag) {
      videoCoverElement.value.style.opacity = 1;
      videoElement.value.style.opacity = 0;
      camera_cover_flag = true;
    }
  }
  if (props.type === "face") {
    if (faceResults.faceLandmarks.length == 0 && camera_cover_flag) {
      videoCoverElement.value.style.opacity = 0;
      videoElement.value.style.opacity = 1;
      camera_cover_flag = false;
    } else if (faceResults.faceLandmarks.length != 0 && !camera_cover_flag) {
      videoCoverElement.value.style.opacity = 1;
      videoElement.value.style.opacity = 0;
      camera_cover_flag = true;
    }
  }
  if (props.type === "hand") {
    if (handResults.landmarks.length == 0 && camera_cover_flag) {
      videoCoverElement.value.style.opacity = 0;
      videoElement.value.style.opacity = 1;
      camera_cover_flag = false;
    } else if (handResults.landmarks.length != 0 && !camera_cover_flag) {
      videoCoverElement.value.style.opacity = 1;
      videoElement.value.style.opacity = 0;
      camera_cover_flag = true;
    }
  }

  // Process(Pose)
  let connector_color = "white";
  if (poseResults !== undefined) {
    store.commit("Camera/setPoseLandmark", poseResults.landmarks[0]);
    for (let i = 0; i < poseResults.landmarks.length; i++) {
      let pose = getPoseInfo(
        poseResults.landmarks[i],
        poseResults.worldLandmarks[i]
      );
      pose_name.value = recognizePose(pose, props.blockType);
      store.commit("Camera/setActionPresent", pose_name.value);

      // if (pose.body.direction == "left") {
      //   connector_color = "green";
      // }
      // if (pose.body.direction == "right") {
      //   connector_color = "yellow";
      // }
    }
  }
  // Process(Face)
  //if (faceResults.faceLandmarks) {
  if (faceResults !== undefined) {
    store.commit("Camera/setFaceLandmark", faceResults.faceLandmarks[0]);
    for (const bshape of faceResults.faceBlendshapes) {
      const faceScore = getFaceScoreFromBshape(bshape);
      store.commit("Camera/setFaceScore", faceScore);
      face_name.value = recognizeFace(bshape, props.blockType);
      store.commit("Camera/setFacePresent", face_name.value);
    }
  }
  //}
  // Process(Hand)
  if (handResults !== undefined) {
    if (handResults.landmarks.length > 0) {
      store.commit("Camera/setHandLandmark", handResults.landmarks[0]);
      // }
      //  for (let i = 0; i < handResults.landmarks.length; i++) {
      let i = 0;
      const isRightHand = handResults.handedness[i][0].categoryName === "Right";
      const handPalmDirection = getHandPalmDirection(
        handResults.landmarks[i],
        isRightHand
      );
      //console.log(handPalmDirection);
      const { x, y, z } = handPalmDirection;
      const absX = Math.abs(x);
      const absY = Math.abs(y);
      const absZ = Math.abs(z);
      let direction = "";
      if (absX > absY && absX > absZ) {
        if (x >= 0) direction = "right";
        else direction = "left";
      } else if (absY > absZ) {
        if (y >= 0) direction = "up";
        else direction = "down";
      } else {
        if (z >= 0) direction = "front";
        else direction = "back";
      }
      //console.log(direction);

      const handAngles = getHandAngles(handResults.landmarks[i]);
      const handStatus = {
        thumb: handAngles.thumb[3] < 150,
        index: handAngles.index[3] < 130,
        middle: handAngles.middle[3] < 130,
        ring: handAngles.ring[3] < 130,
        pinky: handAngles.pinky[3] < 130,
      };
      store.commit("Camera/setHandStatus", handStatus);
      //console.log(handStatus);
      hand_name.value = "";
      const { thumb, index, middle, ring, pinky } = handStatus;
      let statusCnt = 0;
      if (index) statusCnt++;
      if (middle) statusCnt++;
      if (ring) statusCnt++;
      if (pinky) statusCnt++;

      if (props.blockType === "normal") {
        if (!thumb && !index && !middle && !ring && !pinky) {
          hand_name.value = direction + " go";
          if (direction == "front" || direction == "back") hand_name.value = "";
        }
        if (statusCnt >= 2) {
          hand_name.value = "grab";
        }
        if (index && middle && ring && pinky) {
          hand_name.value = "circle";
        }
        if (!index && middle && ring && !pinky) {
          hand_name.value = "shoot";
        }
      }
      if (props.blockType === "rotate") {
        if (direction == "front") hand_name.value = "go";
        if (direction == "left") hand_name.value = "left turn";
        if (direction == "right") hand_name.value = "right turn";
        if (direction == "up") hand_name.value = "jump";
        if (index && middle && ring && pinky) {
          hand_name.value = "circle";
        }
        if (!index && middle && ring && !pinky) {
          hand_name.value = "shoot";
        }
      }
      store.commit("Camera/setHandPresent", hand_name.value);
    }
  }

  // Drawing
  if (props.pose) {
    for (const landmarks of poseResults.landmarks) {
      drawingUtils.drawLandmarks(landmarks, {
        radius: (data) => DrawingUtils.lerp(data.from.z, -0.15, 0.1, 5, 1),
      });
      drawingUtils.drawConnectors(landmarks, PoseLandmarker.POSE_CONNECTIONS, {
        color: connector_color,
      });
    }
  }
  if (props.face) {
    for (const landmarks of faceResults.faceLandmarks) {
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_TESSELATION,
        { color: "#C0C0C070", lineWidth: 1 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
        { color: "#30FF30", lineWidth: 2 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
        { color: "#30FF30", lineWidth: 2 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
        { color: "#30FF30", lineWidth: 2 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
        { color: "#30FF30", lineWidth: 2 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
        { color: "#E0E0E0", lineWidth: 2 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LIPS,
        {
          color: "#30FF30",
          lineWidth: 2,
        }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
        { color: "#30FF30", lineWidth: 2 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
        { color: "#30FF30", lineWidth: 2 }
      );
    }
  }
  if (props.hand) {
    for (const landmarks of handResults.landmarks) {
      drawingUtils.drawConnectors(landmarks, HandLandmarker.HAND_CONNECTIONS, {
        color: "#FFFFFF",
        lineWidth: 5,
      });
    }
  }
  ctx.restore();
  window.requestAnimationFrame(predictWebcam);
}
</script>

<template>
  <div class="wrap" @click="clickView">
    <div class="viewing" ref="viewingElement">
      <div class="wrap_loader" v-if="isCameraLoading">
        <span class="loader"></span>
      </div>

      <video ref="videoElement" class="video" autoplay playsinline></video>
      <div ref="videoCoverElement" class="video_cover" style="opacity: 0"></div>
      <canvas ref="canvasElement"></canvas>
      <div class="camera_block" v-if="props.block">
        <bety-camera-block
          :name="pose_name"
          :blockType="props.blockType"
          v-if="props.type === 'pose'"
        />
        <bety-camera-block
          :name="face_name"
          :blockType="props.blockType"
          v-if="props.type === 'face'"
        />
        <bety-camera-block
          :name="hand_name"
          :blockType="props.blockType"
          v-if="props.type === 'hand'"
        />
      </div>
      <div class="title">
        <div class="title_icon">
          <bety-camera-icon />
        </div>
        <div class="title_text">베티</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  position: relative;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 -4px 8px rgba(0, 0, 0, 0.05),
    -8px 0 12px rgba(0, 0, 0, 0.05), 8px 0 12px rgba(0, 0, 0, 0.05);
}

.viewing {
  flex: 1;
  position: relative;
  border-radius: inherit;
}

.camera_block {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  font-size: 50px;
  color: white;
  bottom: 50px;
  left: 0;
}
.video {
  transition: opacity 0.5s;
}
.video_cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;
  background-color: rgba(0, 0, 0, 0.6);
  /* background-image: url("@/assets/bg_camera.png"); */
  /* background-color: black; */
  background-size: cover;
  background-position: center;
  border-radius: inherit;
}

video {
  object-fit: none;
  clear: both;
  display: block;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  display: block;
  border-radius: inherit;
}

canvas {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: inherit;
}

.title {
  position: absolute;
  top: 20px;
  left: 25px;
  height: 30px;
  display: flex;
  align-items: center;
}

.title_icon {
  width: 24px;
  height: 24px;
  margin-right: 7px;
}
.title_text {
  color: white;
  font-size: 25px;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wrap_loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: #fff;
  box-shadow: 30px 0 0 var(--color-bety-complementary);
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: var(--color-bety-complementary);
  box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--color-bety-complementary);
  }
  50% {
    box-shadow: 0 0 0 var(--color-bety-complementary);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--color-bety-complementary);
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #fff;
  }
  50% {
    box-shadow: 0 0 0 #fff;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #fff;
    margin-top: 0;
  }
}
</style>
