import {
  PoseLandmarker,
  FaceLandmarker,
  HandLandmarker,
  FilesetResolver,
} from "@mediapipe/tasks-vision";

export async function createLandmarkers(numPoses, numFaces, numHands) {
  const vision = await FilesetResolver.forVisionTasks(
    "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.20/wasm"
  );
  let poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
    baseOptions: {
      //modelAssetPath: `@/assets/pose_landmarker_lite.task`,
      modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_lite/float16/1/pose_landmarker_lite.task`,
      delegate: "GPU",
    },
    runningMode: "VIDEO",
    numPoses,
    minPoseDetectionConfidence: 0.6,
  });
  let faceLandmarker = await FaceLandmarker.createFromOptions(vision, {
    baseOptions: {
      modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
      delegate: "GPU",
    },
    outputFaceBlendshapes: true,
    outputFacialTransformationMatrixes: true,
    runningMode: "VIDEO",
    numFaces,
  });
  let handLandmarker = await HandLandmarker.createFromOptions(vision, {
    baseOptions: {
      modelAssetPath: `https://storage.googleapis.com/mediapipe-models/hand_landmarker/hand_landmarker/float16/1/hand_landmarker.task`,
      delegate: "GPU",
    },
    runningMode: "VIDEO",
    numHands,
    minPoseDetectionConfidence: 0.5,
    minHandPresenceConfidence: 0.5,
    minTrackingConfidence: 0.5,
  });
  return [poseLandmarker, faceLandmarker, handLandmarker];
}

export function getFaceScoreFromBshape(bshape) {
  const scoreBrow = bshape.categories[3].score;
  const scoreFaceUpDown =
    (bshape.categories[11].score + bshape.categories[12].score) / 2;
  const scoreFaceLeft =
    (bshape.categories[13].score + bshape.categories[16].score) / 2;
  const scoreFaceRight =
    (bshape.categories[14].score + bshape.categories[15].score) / 2;
  const scoreEyeBlinkLeft = bshape.categories[9].score;
  const scoreEyeBlinkRight = bshape.categories[10].score;
  const scoreMouthOpen = bshape.categories[25].score;
  const scoreMouthLeft = bshape.categories[33].score;
  const scoreMouthRight = bshape.categories[39].score;
  const scoreMouthPucker = bshape.categories[38].score;
  const scoreMouthRoll =
    (bshape.categories[40].score + bshape.categories[41].score) / 2;
  const scoreMouthSmile =
    (bshape.categories[44].score + bshape.categories[45].score) / 2;

  const score = {
    brow: scoreBrow, // > 0.65, < 0.05
    faceUpDown: scoreFaceUpDown, // > 0.7, < 0.2
    faceLeft: scoreFaceLeft, // > 0.6
    faceRight: scoreFaceRight, // > 0.6
    eyeBlinkLeft: scoreEyeBlinkLeft, // > 0.4
    eyeBlinkRight: scoreEyeBlinkRight, // > 0.4
    mouthOpen: scoreMouthOpen, // > 0.5
    mouthLeft: scoreMouthLeft, // > 0.5
    mouthRight: scoreMouthRight, // > 0.5
    mouthPucker: scoreMouthPucker, // > 0.8
    mouthRoll: scoreMouthRoll, // > 0.8
    mouthSmile: scoreMouthSmile, // > 0.5
  };
  return score;
}

export function logExpression(score) {
  if (score.brow > 0.65) console.log("brow up");
  if (score.brow < 0.05) console.log("brow down");
  if (score.faceUpDown > 0.7) console.log("face up");
  if (score.faceUpDown < 0.2) console.log("face down");
  if (score.faceLeft > 0.5) console.log("face left");
  if (score.faceRight > 0.5) console.log("face right");
  if (score.eyeBlinkLeft > 0.5) console.log("eye blink left");
  if (score.eyeBlinkRight > 0.5) console.log("eye blink right");
  if (score.mouthOpen > 0.5) console.log("mouth open");
  if (score.mouthLeft > 0.5) console.log("mouth left");
  if (score.mouthRight > 0.5) console.log("mouth right");
  if (score.mouthPucker > 0.8) console.log("mouth pucker");
  if (score.mouthRoll > 0.8) console.log("mouth roll");
  if (score.mouthSmile > 0.5) console.log("mouth smile");
}

export function recognizeFace(bshape, blockType) {
  const score = getFaceScoreFromBshape(bshape);

  let face = {
    brow: "center",
    faceDirection: "center",
    eyeBlinkLeft: false,
    eyeBlinkRight: false,
    mouthOpen: false,
    mouthLeft: false,
    mouthRight: false,
    mouthPucker: false,
    mouthRoll: false,
    mouthSmile: false,
  };
  if (score.brow > 0.65) face.brow = "up";
  else if (score.brow < 0.05) face.brow = "down";

  if (score.faceLeft > 0.5) face.faceDirection = "left";
  else if (score.faceRight > 0.5) face.faceDirection = "right";
  else if (score.faceUpDown > 0.7) face.faceDirection = "up";
  else if (score.faceUpDown < 0.2) face.faceDirection = "down";

  if (score.eyeBlinkLeft > 0.5) face.eyeBlinkLeft = true;
  if (score.eyeBlinkRight > 0.5) face.eyeBlinkRight = true;
  if (score.mouthOpen > 0.5) face.mouthOpen = true;
  if (score.mouthLeft > 0.5) face.mouthLeft = true;
  if (score.mouthRight > 0.5) face.mouthRight = true;
  if (score.mouthPucker > 0.93) face.mouthPucker = true;
  if (score.mouthRoll > 0.8) face.mouthRoll = true;
  if (score.mouthSmile > 0.5) face.mouthSmile = true;

  ///
  let face_command = "";
  if (blockType === "normal") {
    if (face.mouthRoll || face.mouthPucker) {
      return "shoot";
    }
    if (face.mouthSmile) {
      return "circle";
    }
    switch (face.faceDirection) {
      case "left":
        face_command = "left go";
        break;
      case "right":
        face_command = "right go";
        break;
      case "up":
        face_command = "up go";
        break;
      case "down":
        face_command = "down go";
        break;
    }
  }
  if (blockType === "rotate") {
    if (face.mouthRoll || face.mouthPucker) {
      return "shoot";
    }
    if (face.mouthSmile) {
      return "circle";
    }
    switch (face.faceDirection) {
      case "left":
        face_command = "left turn";
        break;
      case "right":
        face_command = "right turn";
        break;
      case "up":
        face_command = "go";
        break;
      case "down":
        face_command = "jump";
        break;
    }
  }
  return face_command;
}

export function recognizePose(pose, blockType) {
  let pose_command = "";
  if (blockType === "normal") {
    if (
      pose.body.left.shoulder == "down" &&
      pose.body.right.shoulder == "down" &&
      ((pose.body.left.elbow == "straight" &&
        pose.body.right.elbow == "curl") ||
        (pose.body.left.elbow == "curl" && pose.body.right.elbow == "straight"))
    )
      pose_command = "shoot";

    if (
      pose.body.left.shoulder != "down" &&
      pose.body.right.shoulder == "down"
    ) {
      if (
        pose.body.left.elbow == "straight" &&
        pose.body.right.elbow == "straight"
      )
        pose_command = "left go";
    }

    if (
      pose.body.left.shoulder == "down" &&
      pose.body.right.shoulder != "down"
    ) {
      if (
        pose.body.left.elbow == "straight" &&
        pose.body.right.elbow == "straight"
      )
        pose_command = "right go";
    }

    if (pose.body.left.knee == "curl" && pose.body.right.knee == "curl") {
      pose_command = "down go";
    }

    if (pose.body.left.shoulder == "up" && pose.body.right.shoulder == "up") {
      if (
        pose.body.left.elbow == "straight" &&
        pose.body.right.elbow == "straight"
      )
        pose_command = "up go";
      if (pose.body.left.elbow == "curl" && pose.body.right.elbow == "curl")
        pose_command = "circle";
    }
  }

  if (blockType === "rotate") {
    if (
      pose.body.left.shoulder == "down" &&
      pose.body.right.shoulder == "down" &&
      ((pose.body.left.elbow == "straight" &&
        pose.body.right.elbow == "curl") ||
        (pose.body.left.elbow == "curl" && pose.body.right.elbow == "straight"))
    )
      pose_command = "shoot";

    if (
      pose.body.left.shoulder != "down" &&
      pose.body.right.shoulder == "down"
    ) {
      if (
        pose.body.left.elbow == "straight" &&
        pose.body.right.elbow == "straight"
      )
        pose_command = "left turn";
    }

    if (
      pose.body.left.shoulder == "down" &&
      pose.body.right.shoulder != "down"
    ) {
      if (
        pose.body.left.elbow == "straight" &&
        pose.body.right.elbow == "straight"
      )
        pose_command = "right turn";
    }

    if (pose.body.left.knee == "curl" && pose.body.right.knee == "curl") {
      pose_command = "jump";
    }

    if (pose.body.left.shoulder == "up" && pose.body.right.shoulder == "up") {
      if (
        pose.body.left.elbow == "straight" &&
        pose.body.right.elbow == "straight"
      )
        pose_command = "go";
      if (pose.body.left.elbow == "curl" && pose.body.right.elbow == "curl")
        pose_command = "circle";
    }
  }
  return pose_command;
}

export function getPoseInfo(poseLandmarks, poseWorldLandmarks) {
  let pose = {
    face: {
      exp: "none",
    },
    body: {
      direction: "front",
      left: {
        shoulder: "middle", //up, middle, down
        elbow: "straight", // straight, curl
        heap: "straight", // straight, curl
        knee: "straight", // straight, curl
      },
      right: {
        shoulder: "middle", //up, middle, down
        elbow: "straight", // straight, curl
        heap: "straight", // straight, curl
        knee: "straight", // straight, curl
      },
    },
  };
  const angles = getJointAngles(poseWorldLandmarks);
  const faceAngles = getFaceAngles(poseLandmarks);
  // Direction
  if (faceAngles.x > 62 && faceAngles.x_dir) {
    pose.body.direction = "left";
  }
  if (faceAngles.x > 62 && !faceAngles.x_dir) {
    pose.body.direction = "right";
  }

  // Shoulder
  if (angles.ls[2] >= 120) {
    pose.body.left.shoulder = "up";
  } else if (angles.ls[2] <= 60) {
    pose.body.left.shoulder = "down";
  }
  if (angles.rs[2] >= 120) {
    pose.body.right.shoulder = "up";
  } else if (angles.rs[2] <= 60) {
    pose.body.right.shoulder = "down";
  }
  // Elbow
  if (angles.le[2] <= 120) {
    pose.body.left.elbow = "curl";
  }
  if (angles.re[2] <= 120) {
    pose.body.right.elbow = "curl";
  }
  // Heap
  if (angles.rh[2] <= angles.lh[2]) {
    if (angles.rh[2] < 150) {
      pose.body.right.heap = "curl";
    }
  } else {
    if (angles.lh[2] < 150) {
      pose.body.left.heap = "curl";
    }
  }
  // Knee
  if (pose.body.direction == "front") {
    if (angles.lk[0] <= 110) {
      pose.body.left.knee = "curl";
    }
    if (angles.rk[0] <= 110) {
      pose.body.right.knee = "curl";
    }
  } else {
    if (angles.lk[2] <= 110) {
      pose.body.left.knee = "curl";
    }
    if (angles.rk[2] <= 110) {
      pose.body.right.knee = "curl";
    }
  }
  return pose;
}
function getFaceAngles(landmarks) {
  //A: left ear, B: right ear, C: nose
  let A = landmarks[7];
  let B = landmarks[8];
  let C = landmarks[0];
  let AB = [B.x - A.x, B.y - A.y, B.z - A.z];
  let AC = [C.x - A.x, C.y - A.y, C.z - A.z];
  let dotABAB = AB[0] * AB[0] + AB[1] * AB[1] + AB[2] * AB[2];
  let dotABAC = AB[0] * AC[0] + AB[1] * AC[1] + AB[2] * AC[2];
  let t = dotABAC / dotABAB;

  let DC = {};
  DC.x = AC[0] - t * AB[0];
  DC.y = AC[1] - t * AB[1];
  DC.z = AC[2] - t * AB[2];

  const copy = (obj) => {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    const deepCopyObj = {};

    for (let key in obj) {
      deepCopyObj[key] = copy(obj[key]);
    }

    return deepCopyObj;
  };
  let DC_xAxis = copy(DC);
  let DC_yAxis = copy(DC);
  let DC_zAxis = copy(DC);
  DC_xAxis.x = 0;
  DC_yAxis.y = 0;
  DC_zAxis.z = 0;

  let angle_x = calculateAngle(DC, { x: 0, y: 0, z: 0 }, DC_xAxis)[3];
  let angle_y = calculateAngle(DC, { x: 0, y: 0, z: 0 }, DC_yAxis)[3];
  let angle_z = calculateAngle(DC, { x: 0, y: 0, z: 0 }, DC_zAxis)[3];

  //console.log(angle_x, angle_y, angle_z);

  let x_dir = DC.x > 0 ? true : false;
  return { x: angle_x, y: angle_y, z: angle_z, x_dir };
}
function getJointAngles(landmarks) {
  let angles = {};
  /*
   * LEFT / RIGHT
   * ELBOW / SHOULDER / HIP / KNEE
   */
  angles.re = calculateAngle(landmarks[16], landmarks[14], landmarks[12]);
  angles.le = calculateAngle(landmarks[11], landmarks[13], landmarks[15]);
  angles.rs = calculateAngle(landmarks[14], landmarks[12], landmarks[24]);
  angles.ls = calculateAngle(landmarks[13], landmarks[11], landmarks[23]);
  angles.rh = calculateAngle(landmarks[12], landmarks[24], landmarks[26]);
  angles.lh = calculateAngle(landmarks[11], landmarks[23], landmarks[25]);
  angles.rk = calculateAngle(landmarks[24], landmarks[26], landmarks[28]);
  angles.lk = calculateAngle(landmarks[23], landmarks[25], landmarks[27]);
  return angles;
}

const findPerpendicularVector = (A, B, C) => {
  // 벡터 AB
  const AB = {
    x: B.x - A.x,
    y: B.y - A.y,
    z: B.z - A.z,
  };

  // 벡터 AC
  const AC = {
    x: C.x - A.x,
    y: C.y - A.y,
    z: C.z - A.z,
  };

  // 법선 벡터 N
  const N = {
    x: AB.y * AC.z - AB.z * AC.y,
    y: AB.z * AC.x - AB.x * AC.z,
    z: AB.x * AC.y - AB.y * AC.x,
  };

  // 법선 벡터의 크기 계산
  const lengthN = Math.sqrt(N.x * N.x + N.y * N.y + N.z * N.z);

  // 수직인 단위 벡터 V
  const V = {
    x: (N.x / lengthN).toFixed(2),
    y: (N.y / lengthN).toFixed(2),
    z: (N.z / lengthN).toFixed(2),
  };

  return V;
};

export function getHandPalmDirection(landmarks, isRight = true) {
  if (isRight) {
    return findPerpendicularVector(landmarks[0], landmarks[17], landmarks[5]);
  } else {
    return findPerpendicularVector(landmarks[0], landmarks[5], landmarks[17]);
  }
}

export function getHandAngles(landmarks) {
  let angles = {};

  angles.thumb = calculateAngle(landmarks[2], landmarks[3], landmarks[4]);
  angles.index = calculateAngle(landmarks[5], landmarks[6], landmarks[7]);
  angles.middle = calculateAngle(landmarks[9], landmarks[10], landmarks[11]);
  angles.ring = calculateAngle(landmarks[13], landmarks[14], landmarks[15]);
  angles.pinky = calculateAngle(landmarks[17], landmarks[18], landmarks[19]);
  return angles;
}

function calculateAngle(A, B, C) {
  const vectors = {
    noAxis: { x: 1, y: 1, z: 1 },
    xAxis: { x: 0, y: 1, z: 1 }, // x축에 대응하는 단위 벡터
    yAxis: { x: 1, y: 0, z: 1 }, // y축에 대응하는 단위 벡터
    zAxis: { x: 1, y: 1, z: 0 }, // z축에 대응하는 단위 벡터
  };
  const vectorBA = { x: B.x - A.x, y: B.y - A.y, z: B.z - A.z };
  const vectorBC = { x: B.x - C.x, y: B.y - C.y, z: B.z - C.z };

  const angleX = calculateAngleBetweenVectors(
    vectorBA,
    vectorBC,
    vectors.xAxis
  );
  const angleY = calculateAngleBetweenVectors(
    vectorBA,
    vectorBC,
    vectors.yAxis
  );
  const angleZ = calculateAngleBetweenVectors(
    vectorBA,
    vectorBC,
    vectors.zAxis
  );
  const angle = calculateAngleBetweenVectors(
    vectorBA,
    vectorBC,
    vectors.noAxis
  );
  return [angleX, angleY, angleZ, angle];
}
function calculateAngleBetweenVectors(v1, v2, axis) {
  const mag1 = Math.sqrt(
    v1.x * v1.x * axis.x + v1.y * v1.y * axis.y + v1.z * v1.z * axis.z
  );
  const mag2 = Math.sqrt(
    v2.x * v2.x * axis.x + v2.y * v2.y * axis.y + v2.z * v2.z * axis.z
  );
  const dotProduct =
    v1.x * v2.x * axis.x + v1.y * v2.y * axis.y + v1.z * v2.z * axis.z;

  const cosTheta = dotProduct / (mag1 * mag2);
  const angleInRadians = Math.acos(cosTheta);
  const angleInDegrees = angleInRadians * (180 / Math.PI); // 라디안을 도로 변환

  return Math.round(angleInDegrees); // 각도를 정수형으로 반올림하여 반환
}
